<template>
  <div>
    <nav-bar/>
    <div class="outside">
      <div class="container content">
        <div class="content-left">
          <div class="title">系统公告</div>
          <ul>
            <div v-for="(item, index) in navList" :key="index">
              <li @click="navClick(index)" :class="{'active': activeIndex == index}">{{item}}<i class="el-icon-arrow-right"></i></li>
            </div>
          </ul>
        </div>
        <div class="content-right">
          <div class="list">
            <div v-for="(item, index) in notices.data" :key="index">
              <notice-list-item :index="notices.data[0]" :currentPage="notices.current_page" :noticeListItem="item"/>
            </div>
          </div>
          <div>
            <el-pagination
              class="pagination"
              background
              @current-change="currentChange"
              :page-size="notices.per_page"
              :current-page="notices.current_page"
              layout="prev, pager, next"
              :total="notices.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
import NoticeListItem from './childComps/NoticeListItem'
import { getNoticeAll, getNoticeSelect } from 'network/api'

export default {
  components: {
    NavBar,
    NoticeListItem
  },
  data () {
    return {
      navList: ['全部公告', '系统升级', '仓库快递', '内测邀请'],
      activeIndex: 0,
      notices: {},
      currentPage: 1 // 当前页
    }
  },
  methods: {
    // 点击导航列表
    navClick (index) {
      this.activeIndex = index
      switch (index) {
        case 0:
          this.getNoticeAll({ page: 1 })
          break
        case 1:
          this.getNotice({ typedata: 1, page: 1 })
          break
        case 2:
          this.getNotice({ typedata: 2, page: 1 })
          break
        case 3:
          this.getNotice({ typedata: 3, page: 1 })
          break
      }
    },
    // 获取分类筛选公告
    getNotice (params) {
      getNoticeSelect(params).then(res => {
        this.notices = res.data
      })
    },
    // 获取全部公告
    getNoticeAll (params) {
      getNoticeAll(params).then(res => {
        this.notices = res.data
      })
    },
    // 分页改变
    currentChange (val) {
      if (this.activeIndex === 0) {
        this.getNoticeAll({ page: val })
      } else {
        this.getNotice({ typedata: this.activeIndex, page: val })
      }
    }
  },
  created () {
    this.getNoticeAll({ page: 1 })
  }
}
</script>

<style scoped src="styles/views/notice/notice.css">
</style>

<style>
  .el-tabs__content {
    margin-top: 100px;
  }
  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    color: rgba(0,0,0,.45);
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #409EFF;
    border: 1px solid #409EFF;
    background-color: #ffffff;
  }
</style>
